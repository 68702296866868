//Packages
import React from 'react'
import { navigate } from 'gatsby'

//Layouts
import { MainLayout } from '../../../layout/MainLayout'

//Containers
import LoginContainer from '../../../containers/LoginContainer'

// Assets
import { isBrowser, isLoggedIn } from '../../../utils/storage'

const LoginPage: React.FC = () => {
  return (
    <MainLayout>
      {!isLoggedIn() && <LoginContainer />}
      {isLoggedIn() && isBrowser() && navigate('/')}
    </MainLayout>
  )
}

export default LoginPage
